import React, { useEffect, useState } from "react";
import MyContainer from "../../myComponent/MyContainer";
import Filters from "../../components/Filters";
import { CustomBtn } from "../../myComponent/CustomBtn";
import InfiniteScrollList from "../../myComponent/InfiniteScrollList";
import NoDataFound from "../../pages/User/components/NoDataFound";
import { useNavigate } from "react-router-dom";
import CardHeader from "../../components/CardHeader";
import { leadHeaderItems } from "../../utils/menuItemCrm";
import { AddIcon } from "@chakra-ui/icons";
import LeadListItem from "./component/LeadListItem";
import { useDisclosure } from "@chakra-ui/react";
import { useLeadQuery } from "./useLeadQuery/useLeadQuery";
import AddNote from "./component/AddNote";
import ChangeStatus from "./component/ChangeStatus";
import { useInView } from "react-intersection-observer";
import { useProfileQuery } from "../../Queries/auth/useProfileQuery";

const LeadList = () => {
  const defaultStatus = "new";
  const [leadStatus, setLeadStatus] = useState(() => {
    return localStorage.getItem('selectedLeadStatus') || defaultStatus;
  });

  const navigate = useNavigate();
  const [leads, setLeads] = useState([]);
  const [search, setSearch] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedLeadData, setSelectedLeadData] = useState(null);
  const [modalType, setModalType] = useState(null);
  const { ref, inView } = useInView();
  const { data: auth } = useProfileQuery();

  const {
    data: allLead,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
    refetch,
  } = useLeadQuery({ search, status: leadStatus });

  const handleOpenModal = (type, data) => {
    setModalType(type);
    setSelectedLeadData(data);
    onOpen();
  };

  useEffect(() => {
    refetch();
    localStorage.setItem('selectedLeadStatus', leadStatus); // Store status in local storage
  }, [leadStatus, refetch]);


  const buttonConfigs = [
    {
      title: "Reassign",
      actionType: "Reassign",
      onClick: () => navigate("/lead/assign-lead", { state: { actionType: "Reassign" } }),
    },
    {
      title: "Assign",
      actionType: "Assign",
      onClick: () => navigate("/lead/assign-lead", { state: { actionType: "Assign" } }),
    },
  ];

  return (
    <>
      <MyContainer
        header="Lead"
        subHeader={"Lead List"}
        isheaderWhite
        btnComponent={
          <>
            <Filters onSearchChange={setSearch} />
            <AddIcon
              style={{
                backgroundColor: "9A4D49",
                borderRadius: "5px",
                padding: "5px",
                fontSize: "25px",
                color: "white",
                cursor: "pointer",
                fontWeight: "800",
                boxShadow: "black 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
              }}
              onClick={() => navigate("/lead/addlead")}
            />
            {auth && (
              <>
                {buttonConfigs.map((btnConfig, index) => (
                  <CustomBtn
                    key={index}
                    title={btnConfig.title}
                    onClick={btnConfig.onClick}
                  />
                ))}
              </>
            )}
          </>
        }
      >
        <CardHeader
          value={leadStatus}
          items={leadHeaderItems}
          onChange={(v) => setLeadStatus(v)}
          defaultStatus={defaultStatus}
          module='lead'
        />
        <InfiniteScrollList
          data={allLead || []}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
          isFetching={isFetching}
          renderItem={(item) => (
            <LeadListItem
              item={item}
              onClickBox={() => navigate("/lead/lead-details", { state: item })}
              onClickNotes={(e) => {
                e.stopPropagation();
                handleOpenModal("notes", item);
              }}
              onStatusChange={(e) => {
                e.stopPropagation();
                handleOpenModal("statusChange", item);
              }}
            />
          )}
          loadingMessage="Loading Leads..."
          errorMessage="Error fetching Leads"
          noDataMessage={<NoDataFound message="No Lead In The System" name="NoUser" />}
          gap={2}
          containerStyle={{ marginTop: "1rem" }}
        />
      </MyContainer>

      {modalType === "notes" && (
        <AddNote isOpen={isOpen} onClose={onClose} data={selectedLeadData} />
      )}
      {modalType === "statusChange" && (
        <ChangeStatus
          isOpen={isOpen}
          onClose={onClose}
          data={selectedLeadData}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default LeadList;
