// import "./App.css";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Loadable from "./components/Loadable";
import { lazy } from "react";
import { ForgetPassword } from "./pages/Auth/ForgetPassword.jsx";
import AppointmentLetter from "./pages/AppointmentLetter/AppointmentLetter.jsx";
import LeadList from "./pagesCRM/Lead/LeadList.jsx";
import { Lazy } from "yup";
import { Media } from "reactstrap";

const Login = Loadable(lazy(() => import("./pages/Auth/Login")));
const VerifyOTP = Loadable(lazy(() => import("./pages/Auth/VerifyOTP.jsx")));
const ResetPassword = Loadable(
  lazy(() => import("./pages/Auth/ResetPassword.jsx"))
);
const Dashboard = Loadable(lazy(() => import("./pages/Dashboard/index.js")));
const MainLayout = Loadable(
  lazy(() => import("./components/layout/MainLayout.jsx"))
);
const UserList = Loadable(lazy(() => import("./pages/User/UserList.jsx")));
const Notification = Loadable(
  lazy(() => import("./pages/Notification/Notification.jsx"))
);
const NotificationDetail = Loadable(
  lazy(() => import("./pages/Notification/NotificationDetail.jsx"))
);
const DocuSuccess = Loadable(
  lazy(() => import("./components/User/docusignSuccess.jsx"))
);
const LeaveList = Loadable(lazy(() => import("./pages/Leave/LeaveList.jsx")));
// const TeamList = Loadable(lazy(() => import("./pages/Team/TeamListing.jsx")));

/* start Attendance */
const AttList = Loadable(lazy(() => import("./pages/Attendance/AttList.jsx")));
const AttDetails = Loadable(
  lazy(() => import("./pages/Attendance/AttDetails.jsx"))
);
const AttUpdate = Loadable(
  lazy(() => import("./pages/Attendance/AttUpdate.jsx"))
);
const MyAttendance = Loadable(
  lazy(() => import("./pages/Attendance/MyAttendance.jsx"))
);
/* end Attendance */

const AddEmployee = Loadable(
  lazy(() => import("./pages/User/AddEmployee.jsx"))
);
const EmployeeDetails = Loadable(
  lazy(() => import("./pages/User/EmployeeDetails.jsx"))
);

/* start Team */
const TeamList = Loadable(lazy(() => import("./pages/Team/TeamList.jsx")));
const AddTeam = Loadable(lazy(() => import("./pages/Team/TeamForm.jsx")));
const TeamDetails = Loadable(
  lazy(() => import("./pages/Team/TeamDetails.jsx"))
);

/* end Team */

const LeaveForm = Loadable(lazy(() => import("./pages/Leave/LeaveForm.jsx")));
const LeaveDetails = Loadable(
  lazy(() => import("./pages/Leave/LeaveDetails.jsx"))
);
const ProfileSettings = Loadable(
  lazy(() => import("./pages/User/ProfileSettings.jsx"))
);
const ChangePasswords = Loadable(
  lazy(() => import("./pages/User/ChangePasswords.jsx"))
);
const GeneralChanges = Loadable(
  lazy(() => import("./pages/User/GeneralChanges.jsx"))
);
const OfferLetter = Loadable(
  lazy(() => import("./pages/User/OfferLetter.jsx"))
);
const OfferLetterList = Loadable(
  lazy(() => import("./pages/User/OfferLetterList.jsx"))
);

// CRM Layout start

const Agents = Loadable(lazy(() => import("./pagesCRM/Agents/AgentList.jsx")));
const AgentDetails = Loadable(
  lazy(() => import("./pagesCRM/Agents/AgentDetails.jsx"))
);
const AgentsTeamList = Loadable(
  lazy(() => import("./pagesCRM/Agents/AgentsTeamList.jsx"))
);
const AgentsRequestShuffle = Loadable(
  lazy(() => import("./pagesCRM/Agents/AgentsShuffle.jsx"))
);
const AgentsCreateTeam = Loadable(
  lazy(() => import("./pagesCRM/Agents/AgentsCreateForm.jsx"))
);
const AgentPermissions = Loadable(
  lazy(() => import("./pagesCRM/Agents/AgentPermission.jsx"))
);

const Lead = Loadable(lazy(() => import("./pagesCRM/Lead/LeadList.jsx")));
const AddLead = Loadable(lazy(() => import("./pagesCRM/Lead/AddLead.jsx")));
const AssignLead = Loadable(
  lazy(() => import("./pagesCRM/Lead/AssignLead.jsx"))
);
const ReassignLead = Loadable(
  lazy(() => import("./pagesCRM/Lead/ReassignLead.jsx"))
);
const LeadDetails = Loadable(
  lazy(() => import("./pagesCRM/Lead/LeadDetails.jsx"))
);

const Booking = Loadable(
  lazy(() => import("./pagesCRM/Booking/BookingList.jsx"))
);
const CreateBooking = Loadable(
  lazy(() => import("./pagesCRM/Booking/CreateBooking.jsx"))
);

const Marketing = Loadable(
  lazy(() => import("./pagesCRM/Marketing/Marketing.jsx"))
);
const Meeting = Loadable(
  lazy(() => import("./pagesCRM/Meetings/MeetingsList.jsx"))
);
const MeetingDetails = Loadable(
  lazy(() => import("./pagesCRM/Meetings/MeetingDetails.jsx"))
);
const CreateMeeting = Loadable(
  lazy(() => import("./pagesCRM/Meetings/CreateMeeting.jsx"))
);

const Reports = Loadable(
  lazy(() => import("./pagesCRM/Reports/ReportList.jsx"))
);
const BookingDetail = Loadable(
  lazy(() => import("./pagesCRM/Booking/BookingDetail.jsx"))
);
const Conversation = Loadable(
  lazy(() => import("./pagesCRM/Conversation/Conversation.jsx"))
);
const MyConversation = Loadable(
  lazy(() => import("./pagesCRM/Conversation/MyConversation.jsx"))
);
const Contacts = Loadable(
  lazy(() => import("./pagesCRM/Contacts/Contacts.jsx"))
);
const Calendar = Loadable(
  lazy(() => import("./pagesCRM/Calendar/Calendar.jsx"))
);
const MyCalendar = Loadable(
  lazy(() => import("./pagesCRM/Calendar/MyCalendar.jsx"))
);
const AgentTeamDetail = Loadable(
  lazy(() => import("./pagesCRM/Agents/TeamDetail.jsx"))
);

const Payments = Loadable(
  lazy(() => import("./pagesCRM/Payments/Payments.jsx"))
);
const TaxInvoiceForm = Loadable(
  lazy(() => import("./pagesCRM/Payments/TaxInvoiceForm.jsx"))
);
const TaxInvoice = Loadable(
  lazy(() => import("./pagesCRM/Payments/TaxInvoice.jsx"))
);
const SocialMedia = Loadable(
  lazy(() => import("./pagesCRM/SocialMedia/SocialMedia.jsx"))
);

const Medias = Loadable(lazy(() => import("./pagesCRM/Medias/Medias.jsx")));

const CrmDashboard = Loadable(
  lazy(() => import("./pagesCRM/CrmDashboard/CrmDashboard.jsx"))
);

const ProjectList = Loadable(
  lazy(() => import("./pagesCRM/Projects/ProjectsList.jsx"))
);

// Category 1: Auth-related routes
const authRoutes = [
  {
    path: "/auth",
    children: [
      { path: "login", element: <Login /> },
      { path: "verifyOTP", element: <VerifyOTP /> },
      { path: "resetpassword", element: <ResetPassword /> },
      { path: "forgetpassword", element: <ForgetPassword /> },
    ],
  },
];

// Category 2: Main application routes
const miscRoutes = [
  {
    path: "/appointmentLetter",
    element: <AppointmentLetter />,
  },
  {
    path: "/docusuccess",
    element: <DocuSuccess />,
  },
  {
    path: "/lead/socialmedia",
    element: <SocialMedia />,
  },
];

// Category 2: Main application routes
const appRoutes = [
  {
    path: "/",
    element: <CrmDashboard />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "users",
    element: <UserList />,
  },
  {
    path: "leaves",
    element: <LeaveList />,
  },
  {
    path: "teams",
    element: <TeamList />,
  },
  {
    path: "users/addEmployee",
    element: <AddEmployee />,
  },
  {
    path: "users/:id",
    element: <EmployeeDetails />,
  },
  {
    path: "users/notification",
    element: <Notification />,
  },
  {
    path: "users/notification/:id",
    element: <NotificationDetail />,
  },
  {
    path: "projectlist",
    element: <ProjectList />,
  },

  /* start Team */
  {
    path: "teams",
    element: <TeamList />,
  },
  {
    path: "teams/add_team",
    element: <AddTeam />,
  },
  {
    path: "teams/:id",
    element: <TeamDetails />,
  },
  /* end Team */
  {
    path: "users/profilesettings",
    element: <ProfileSettings />,
  },
  {
    path: "users/changepassword",
    element: <ChangePasswords />,
  },
  {
    path: "users/generalchanges",
    element: <GeneralChanges />,
  },
  {
    path: "attendance",
    element: <AttList />,
  },
  {
    path: "attendance/:id",
    element: <AttDetails />,
  },
  {
    path: "attendace/AttUpdate",
    element: <AttUpdate />,
  },
  {
    path: "myattendance",
    element: <MyAttendance />,
  },
  {
    path: "leaves/apply_leave",
    element: <LeaveForm />,
  },
  {
    path: "leaves/:id",
    element: <LeaveDetails />,
  },
  {
    path: "users/offerletter",
    element: <OfferLetter />,
  },
  {
    path: "users/offerletterlist",
    element: <OfferLetterList />,
  },
  //CRMS part start
  {
    path: "/agents",
    element: <Agents />,
  },
  {
    path: "/agents/teamlist",
    element: <AgentsTeamList />,
  },
  {
    path: "/agents/agentsdetails",
    element: <AgentDetails />,
  },
  {
    path: "/agents/teamlist/reshuffle",
    element: <AgentsRequestShuffle />,
  },
  {
    path: "/agents/teamlist/createteam",
    element: <AgentsCreateTeam />,
  },
  {
    path: "/agents/teamlist/teamdetail/:id",
    element: <AgentTeamDetail />,
  },
  {
    path: "/agents/agent-permissions",
    element: <AgentPermissions />,
  },
  {
    path: "/booking",
    element: <Booking />,
  },
  {
    path: "/conversation",
    element: <Conversation />,
  },
  {
    path: "/myconversation",
    element: <MyConversation />,
  },
  {
    path: "/booking/createbooking",
    element: <CreateBooking />,
  },
  {
    path: "/booking/bookingdetail",
    element: <BookingDetail />,
  },
  {
    path: "/lead",
    element: <Lead />,
  },
  {
    path: "/lead/addlead",
    element: <AddLead />,
  },
  {
    path: "/lead/assign-lead",
    element: <AssignLead />,
  },
  {
    path: "/lead/reassign-lead",
    element: <ReassignLead />,
  },
  {
    path: "/lead/lead-details",
    element: <LeadDetails />,
  },
  {
    path: "/meetings",
    element: <Meeting />,
  },

  {
    path: "/meetings/meeting-details",
    element: <MeetingDetails />,
  },
  {
    path: "/meetings/create-meetings",
    element: <CreateMeeting />,
  },
  {
    path: "/contacts",
    element: <Contacts />,
  },
  {
    path: "/reports",
    element: <Reports />,
  },
  {
    path: "/marketing",
    element: <Marketing />,
  },
  {
    path: "/calendar",
    element: <Calendar />,
  },
  {
    path: "/calendar/mycalendar",
    element: <MyCalendar />,
  },
  {
    path: "/payments",
    element: <Payments />,
  },
  {
    path: "/payments/create-invoice",
    element: <TaxInvoiceForm />,
  },
  {
    path: "/payments/tax-invoice",
    element: <TaxInvoice />,
  },
  {
    path: "/medias",
    element: <Medias />,
  },
];

// Category 3: Miscellaneous or specific feature routes
function App() {
  const token = localStorage.getItem("token");

  const routes = token
    ? [
        {
          path: "/",
          element: <MainLayout />,
          children: appRoutes,
        },
        ...miscRoutes,
        { path: "*", element: <Navigate to="/dashboard" /> }, // Redirect to dashboard if any other route is hit
      ]
    : [
        ...authRoutes,
        ...miscRoutes,
        { path: "*", element: <Navigate to="/auth/login" /> }, // Redirect to login if any other route is hit
      ];

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
}

export default App;
